<template>
    <div class="pageContentBox">
        <div class="pageTopBox">
            <img class="switchRow" src="@/assets/images/productService/top.png" :alt="$t('header.Tabs2')" />
        </div>
        <div class="vrArTabBox">
            <div class="arArTabList" :class="arIndex == 0 ? 'arActive':''" @click="handleSelect(0)">{{$t('productService.vrArTab1')}}</div>
            <div class="arArTabList" :class="arIndex == 1 ? 'arActive':''" @click="handleSelect(1)">{{$t('productService.vrArTab2')}}</div>
            <div class="arArTabList" :class="arIndex == 2 ? 'arActive':''" @click="handleSelect(2)">{{$t('productService.vrArTab3')}}</div>
        </div>
        <vrPage v-if="arIndex == 0" />
        <arPage v-if="arIndex == 1" />
        <aiPage v-if="arIndex == 2" />
        
        <!-- 底部 -->
        <footerPage />
    </div>
</template>

<script>
    import footerPage from '@/components/footerPage.vue';
    import vrPage from './itemPage/vrPage.vue';
    import arPage from './itemPage/arPage.vue';
    import aiPage from './itemPage/aiPage.vue';
    
    export default {
        name: 'productService',
        components: {
            footerPage,
            vrPage,
            arPage,
            aiPage,
        },
        data() {
            return {
                arIndex: 0,
            };
        },
        mounted(){
           
        },
        methods: {
            handleSelect(index) {
                this.arIndex = index
            }
        }
    }
</script>

<style scoped>
    .pageContentBox{
        width: 100%;
        box-sizing: border-box;
    }
    .pageTopBox{
        width: 100%;
        background: url(../../assets/images/productService/topBg.jpg) no-repeat;
        background-position: right center;
        overflow: hidden;
    }
    .switchRow{
        width: 100%;
        height: auto;
        object-fit: cover;
        display: block;
        transform: translateX(-50%);
        transition: all .3s;
        animation: switchShow 1s ease 1 forwards; /* 动画名称、持续时间、缓动函数、循环次数 */
    }
    @keyframes switchShow {
        0% {
            transform: translateX(-50%); /* 初始位置 */
        }
        100% {
            transform: translateY(0); /* 回到初始位置 */
        }
    }
    .vrArTabBox{
        width: 100%;
        background-color: #f5f5f5;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .arArTabList{
        font-size: 22px;
        color: #333333;
        margin: 0 60px;
        cursor: pointer;
        transition: all .3s;
    }
    .arActive{
        color: #0041d5;
        font-weight: bold;
    }
    .vrarContent{
        width: 100%;

    }
    .vrarMaxwidth{
        width: 100%;
        max-width: 1100px;
        box-sizing: border-box;
        padding: 40px 0;
        margin: 0 auto;
    }
    .vrarTitleBox{
        width: 100%;
        padding: 30px 50px;
        background-color: #0041d5;
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        line-height: 2;
    }
    .vrarProgramme{
        width: calc(100% - 40px);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px 0 0;
        margin: 40px auto;

    }
    
    .vrarRowList{
        width: 450px;
        height: 130px;
        display: flex;
        border-radius: 14px;
        background-color: rgba(218, 244, 253, 0.66);
        box-sizing: border-box;
        padding: 17px;
        position: relative;
    }
    .vrarListIcon{
        width: 60px;
        height: 60px;
        display: block;
        padding-right: 20px;
        position: relative;
        top: -36px;
    }
    .vrarListRight{
        width: calc(100% - 92px);
    }
    .vrarListTitle{
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
    }
    .vrarListDesc{
        width: 100%;
        font-size: 16px;
        color: #333333;
        line-height: 1.6;
    }

    @media only screen and (max-width: 768px) {
        .pageTopBox{
            width: 100%;
            height: 150px;
            background-position: center center;
            background-size: auto 100%;
        }
        .switchRow{
            width: auto;
            height: 100%;
            object-fit: fill;
            position: relative;
            left: -20%;
        }
        .vrArTabBox{
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            padding: 0 20px;
            justify-content: space-between;
        }
        .arArTabList{
            font-size: 14px;
            margin: 0;
            text-align: center;
            cursor: auto;
        }
    }
    
</style>