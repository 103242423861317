import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

// 路由
import router from './router';
router.beforeEach((to, from, next) => {
  /* 路由发生变化的时候修改页面title */
  if (to.name) {
    document.title = to.name;
  }
  /* 路由发生变化的时候修改页面关键词 */ 
  if (to.meta.keywords) {
     document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.keywords)
  }
  /* 路由发生变化的时候修改页面描述 */ 
  if (to.meta.description) {
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description)
 }

  next();
});

// element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import i18n from './locale/i18n'; // 引入i18n配置

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});

new Vue({
  router, // 引入路由
  i18n, // 引入国际化
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
