<template>
    <div class="pageContentBox">
        <div class="pageTopBox">
            <img class="switchRow" src="@/assets/images/aboutUs/top.png" :alt="$t('header.Tabs3') + $t('index.carousel2')" />
        </div>
        <div class="aboutUsMaxwidth">
            <div class="aboutUsRowLeft">
                <div class="aboutUsRowTitle">{{$t('aboutUs.aboutUsTitle')}}</div>
                <div class="aboutUsRowSubtitle">{{$t('aboutUs.aboutUsSubtitle')}}</div>
                <div class="aboutUsRowContent">
                    <div class="aboutUsParagraph">{{$t('aboutUs.aboutUsParagraph1')}}</div>
                    <div class="aboutUsParagraph">{{$t('aboutUs.aboutUsParagraph2')}}</div>
                </div>
            </div>
            <div class="aboutUsIconBox">
                <img class="aboutUsIconImg" src="@/assets/images/aboutUs/icon1.png" :alt="$t('aboutUs.aboutUsTitle')">
            </div>
        </div>
        <div class="aboutUsRowBox">
            <div class="aboutUsRowMaxwidth">
                <div class="aboutUsLeftBox" :class="lang === 'en' ? 'en_width': ''">
                    <div class="aboutUsRowTitle">{{$t('aboutUs.aboutUsTitle')}}</div>
                    <div class="aboutUsRowSubtitle2">{{$t('aboutUs.aboutUsSubtitle2')}}</div>
                    <div class="aboutUsRowContent">
                        <div class="aboutUsParagraph" style="text-indent: 0;">{{$t('aboutUs.aboutUsParagraph3')}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="aboutUsFloatBox">
            <div class="aboutUsIconBox2">
                <img class="aboutUsIcon2" src="@/assets/images/aboutUs/icon2.svg" :alt="$t('aboutUs.aboutUsTitle')">
            </div>
            <div class="aboutUsIdea">
                <div class="ideaList">{{$t('aboutUs.ideaList1')}}</div>
                <div class="ideaList">{{$t('aboutUs.ideaList2')}}</div>
                <div class="ideaList">{{$t('aboutUs.ideaList3')}}</div>
                <div class="ideaList">{{$t('aboutUs.ideaList4')}}</div>
                <div class="ideaList">{{$t('aboutUs.ideaList5')}}</div>
                <div class="ideaList">{{$t('aboutUs.ideaList6')}}</div>
            </div>
        </div>
        <div class="aboutUsWelecomeBox">
            <div class="aboutUsWelecomeMaxWidth">
                <div class="aboutUsRowTitle">{{$t('aboutUs.aboutUsTitle1')}}</div>
                <div class="aboutUsRowSubtitle">{{$t('aboutUs.aboutUsSubtitle1')}}</div>
                <div class="welecomeContent">
                    <div class="welecomeContentList">{{$t('aboutUs.welecomeContentList1')}}</div>
                    <div class="welecomeContentList">{{$t('aboutUs.welecomeContentList2')}}</div>
                </div>
                <div class="ContactUsBox">
                    <div class="contactList">
                        <img class="contactIcon" src="@/assets/images/aboutUs/link1.png" :alt="$t('aboutUs.contactTitle1')">
                        <div class="contactTitle">{{$t('aboutUs.contactTitle1')}}</div>
                        <div class="contactSubtitle">{{$t('aboutUs.contactSubtitle1')}}</div>
                    </div>
                    <div class="contactList">
                        <img class="contactIcon" src="@/assets/images/aboutUs/link2.png" :alt="$t('aboutUs.contactTitle2')">
                        <div class="contactTitle">{{$t('aboutUs.contactTitle2')}}</div>
                        <div class="contactSubtitle">{{$t('aboutUs.contactSubtitle2')}}</div>
                    </div>
                    <div class="contactList">
                        <img class="contactIcon" src="@/assets/images/aboutUs/link3.png" :alt="$t('aboutUs.contactTitle3')">
                        <div class="contactTitle">{{$t('aboutUs.contactTitle3')}}</div>
                        <div class="contactSubtitle">{{$t('aboutUs.contactSubtitle3')}}</div>
                    </div>
                    <div class="contactList">
                        <img class="contactIcon" src="@/assets/images/aboutUs/link4.png" :alt="$t('aboutUs.contactTitle4')">
                        <div class="contactTitle">{{$t('aboutUs.contactTitle4')}}</div>
                        <div class="contactSubtitle">{{$t('aboutUs.contactSubtitle4')}}</div>
                        <div class="contactSubtitle">{{$t('aboutUs.contactSubtitle4_1')}}</div>
                    </div>
                </div>
            </div>
        </div>
  
        
        <!-- 底部 -->
        <footerPage />
    </div>
</template>

<script>
    import footerPage from '@/components/footerPage.vue';
    
    export default {
        name: 'productService',
        components: {
            footerPage
        },
        data() {
            return {
                lang: localStorage.getItem('lang'),
                arIndex: 0,
            };
        },
        watch: {
            '$i18n.locale'(newLocale) {
                console.log('语言已更改为:', newLocale);
                this.lang = newLocale
            }
        },
        mounted(){
           
        },
        methods: {
            handleSelect(index) {
                this.arIndex = index
            }
        }
    }
</script>

<style scoped>
    .pageContentBox{
        width: 100%;
        box-sizing: border-box;
    }
    .pageTopBox{
        width: 100%;
        background: url(../../assets/images/aboutUs/top2.jpg) no-repeat;
        background-position: center center;

    }
    .switchRow{
        width: 100%;
        height: auto;
        object-fit: cover;
        display: block;
        transform: translateX(-50%);
        transition: all .3s;
        animation: switchShow 1s ease 1 forwards; /* 动画名称、持续时间、缓动函数、循环次数 */
    }
    @keyframes switchShow {
        0% {
            transform: translateX(-50%); /* 初始位置 */
        }
        100% {
            transform: translateY(0); /* 回到初始位置 */
        }
    }

    .aboutUsMaxwidth{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        display: flex;
        align-items: center;

    }
    .aboutUsRowLeft{
        width: 70%;
        padding: 40px 0;

    }
    .aboutUsRowTitle{
        font-weight: 700;
        color: #333333;
        letter-spacing: 5.2px;
        font-size: 28px;
    }
    .aboutUsRowSubtitle{
        color: #aaaaaa;
        margin: 20px 0 25px;
        font-size: 16px;
    }
    
    .aboutUsRowContent{
        width: 100%;
    }
    .aboutUsParagraph{
        font-size: 16px;
        color: rgba(51, 51, 51, 0.9);
        margin-bottom: 30px;
        text-indent: 2em;
        line-height: 1.8;
    }
    .aboutUsParagraph:last-child{
        margin: 0;
    }
    .aboutUsIconBox{
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .aboutUsIconImg{
        width: 100%;
        height: auto;
        animation: floating 1.5s ease infinite; /* 动画名称、持续时间、缓动函数、循环次数 */
    }
    /* 定义一个名为floating的动画 */
    @keyframes floating {
        0% {
            transform: translateY(0); /* 初始位置 */
        }
        50% {
            transform: translateY(-10px); /* 向上移动4像素 */
        }
        100% {
            transform: translateY(0); /* 回到初始位置 */
        }
    }
    .aboutUsRowBox{
        width: 100%;
        height: 418px;
        box-sizing: border-box;
        background: url(../../assets/images/aboutUs/rowBg1.png) no-repeat center center;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .aboutUsRowMaxwidth{
        width: 100%;
        max-width: 1100px;
        box-sizing: border-box;
        margin: 0 auto;
    }
    .aboutUsLeftBox{
        width: 40%;
    }
    .en_width{
        width: 53%;
    }
    .en_width .aboutUsRowTitle{
        letter-spacing: normal;
    }
    .aboutUsRowSubtitle2{
        width: 100%;
        color: #00a3ff;
        font-size: 20px;
        font-weight: 700;
        margin: 20px 0 25px;
    }

    .aboutUsFloatBox{
        width: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        position: relative;
        top: -50px;
        border-radius: 20px;
        background-color: #0041d5;
        box-sizing: border-box;
        padding: 30px;
    }
    .aboutUsIconBox2{
        width: 180px;
        height: 180px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 16px;
        overflow: hidden;
    }
    .aboutUsIcon2{
        width: 100%;
        height: 100%;
        display: block;
    }
    .aboutUsIdea{
        width: calc(100% - 180px);
        box-sizing: border-box;
        padding-left: 30px;
    }
    .ideaList{
        font-size: 15px;
        color: rgba(255, 255, 255, 0.9);
        margin-bottom: 14px;
    }
    .ideaList:last-child{
        margin: 0;
    }

    .aboutUsWelecomeBox{
        width: 100%;
        background-image: linear-gradient(-253.62984909991644deg, #e8f4ff 0%, #ffffff 100%);
    }
    .aboutUsWelecomeMaxWidth{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .welecomeContentList{
        color: rgba(51, 51, 51, 0.9);
        font-size: 16px;
        text-align: center;
        line-height: 1.8;
    }
    .ContactUsBox{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 50px 0 0;
    }
    .contactList{
        width: 25%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contactIcon{
        width: 90px;
        height: 90px;
        display: block;
        margin-bottom: 30px;
    }
    .contactTitle{
        width: 100%;
        text-align: center;
        font-weight: 700;
        color: #555555;
        font-size: 20px;
        margin-bottom: 16px;
    }
    .contactSubtitle{
        width: 100%;
        text-align: center;
        font-size: 18px;
        color: #555555;
    }

    @media only screen and (max-width: 768px) {
        .pageTopBox{
            width: 100%;
            height: 150px;
            background-position: center center;
            background-size: auto 100%;
        }
        .switchRow{
            width: auto;
            height: 100%;
            object-fit: fill;
            position: relative;
            left: -20%;
        }
        .vrArTabBox{
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            padding: 0 20px;
            justify-content: space-between;
        }
        .arArTabList{
            font-size: 14px;
            margin: 0;
            cursor: auto;
        }
        .aboutUsMaxwidth{
            max-width: 100%;
            box-sizing: border-box;
            flex-wrap: wrap;
        }
        .aboutUsRowLeft{
            width: 100%;
            box-sizing: border-box;
            padding: 30px 10px 20px;
        }
        .aboutUsRowTitle,.aboutUsRowSubtitle{
            text-align: center;
        }
        .aboutUsIconBox{
            width: 70%;
            margin: 0 auto;
        }
        .aboutUsRowBox{
            width: 100%;
            height: auto;
            margin-bottom: 20px;
            background-size: 200% 100%;
            background-position: left center;
        }
        .aboutUsRowMaxwidth{
            max-width: 100%;
            box-sizing: border-box;
            padding: 20px 10px;
        }
        .aboutUsLeftBox{
            width: 100%;
        }
        .aboutUsFloatBox{
            width: 90%;
            top: 0;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 30px;
        }
        .aboutUsRowSubtitle2{
            text-align: center;
        }
        .aboutUsIdea{
            width: 100%;
            padding: 0;
            margin-top: 20px;
        }
        .ideaList{
            font-size: 14px;
        }
        .aboutUsWelecomeMaxWidth{
            max-width: 100%;
            padding: 30px 0;
        }
        .welecomeContentList{
            font-size: 14px;
        }
        .ContactUsBox{
            padding: 30px 10px 0;
            box-sizing: border-box;
            flex-wrap: wrap;
        }
        .contactList{
            width: 50%;
            box-sizing: border-box;
            padding: 10px 0;
        }
        .contactIcon{
            margin-bottom: 20px;
        }
        .contactTitle{
            font-size: 18px;
            margin-bottom: 4px;
        }
        .contactSubtitle{
            font-size: 14px;
        }
        .aboutUsParagraph{
            font-size: 14px;
        }
    }
    
    

    
</style>