<template>
    <div class="vrarContent">
        <div class="vrarMaxwidth">
            <div class="vrarTitleBox">
                {{ $t('productService.vrarTitleBox') }}
            </div>
            <div class="vrarProgramme">
                <div class="vrarRowList">
                    <img class="vrarListIcon" src="@/assets/images/productService/vrar1.png" :alt="$t('productService.vrarListTitle1')">
                    <div class="vrarListRight">
                        <div class="vrarListTitle">{{ $t('productService.vrarListTitle1') }}</div>
                        <div :class=" lang === 'en' ? 'vrarListDesc enClass' : 'vrarListDesc' ">{{ $t('productService.vrarListDesc1') }}</div>
                    </div>
                </div>
                <div class="vrarRowList">
                    <img class="vrarListIcon" src="@/assets/images/productService/vrar2.png" :alt="$t('productService.vrarListTitle2')">
                    <div class="vrarListRight">
                        <div class="vrarListTitle">{{ $t('productService.vrarListTitle2') }}</div>
                        <div :class=" lang === 'en' ? 'vrarListDesc enClass' : 'vrarListDesc' ">{{ $t('productService.vrarListDesc2') }}</div>
                    </div>
                </div>
            </div>
            <div class="vrarProgramme">
                <div class="vrarRowList">
                    <img class="vrarListIcon" src="@/assets/images/productService/vrar3.png" :alt="$t('productService.vrarListTitle3')">
                    <div class="vrarListRight">
                        <div class="vrarListTitle">{{ $t('productService.vrarListTitle3') }}</div>
                        <div :class=" lang === 'en' ? 'vrarListDesc enClass' : 'vrarListDesc' ">{{ $t('productService.vrarListDesc3') }}</div>
                    </div>
                </div>
                <div class="vrarRowList">
                    <img class="vrarListIcon" src="@/assets/images/productService/vrar4.png" :alt="$t('productService.vrarListTitle4')">
                    <div class="vrarListRight">
                        <div class="vrarListTitle">{{ $t('productService.vrarListTitle4') }}</div>
                        <div :class=" lang === 'en' ? 'vrarListDesc enClass' : 'vrarListDesc' ">{{ $t('productService.vrarListDesc4') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="solutionBox">
            <div class="solutionMaxWidth">
                <div class="solutionTitle">{{ $t('productService.solutionTitle') }}</div>
                <div class="solutionDesc">{{ $t('productService.solutionDesc') }}</div>
                <div class="btnArrBox">
                    <div class="btnList">
                        <img class="btnIconImg" src="@/assets/images/productService/answer.png" :alt="$t('productService.solutionBtn1')">
                        <div class="btnText">{{$t('productService.solutionBtn1')}}</div>
                    </div>
                    <div class="btnList">
                        <img class="btnIconImg" src="@/assets/images/productService/answer.png" :alt="$t('productService.solutionBtn2')">
                        <div class="btnText">{{$t('productService.solutionBtn2')}}</div>
                    </div>
                    <div class="btnList">
                        <img class="btnIconImg" src="@/assets/images/productService/answer.png" :alt="$t('productService.solutionBtn3')">
                        <div class="btnText">{{$t('productService.solutionBtn3')}}</div>
                    </div>
                    <div class="btnList">
                        <img class="btnIconImg" src="@/assets/images/productService/answer.png" :alt="$t('productService.solutionBtn4')">
                        <div class="btnText">{{$t('productService.solutionBtn4')}}</div>
                    </div>
                    <div class="btnList">
                        <img class="btnIconImg" src="@/assets/images/productService/answer.png" :alt="$t('productService.solutionBtn5')">
                        <div class="btnText">{{$t('productService.solutionBtn5')}}</div>
                    </div>
                </div>
            </div>
            <div class="vrSolutionMaxwidth">
                <img class="vrSolutionImg" src="@/assets/images/productService/fangan1.svg" :alt="$t('productService.solutionTitle')">
                <img class="vrSolutionPlayIcon" src="@/assets/images/productService/play.png" :alt="$t('productService.solutionTitle')">
            </div>
        </div>
        <div class="solutionBox last">
            <div class="solutionMaxWidth">
                <div class="solutionTitle">{{$t('productService.solutionTitle2')}}</div>
                <div class="solutionDesc">{{$t('productService.solutionDesc2')}}</div>
            </div>
            <div class="vrSolutionMaxwidth">
                <img class="vrSolutionImg" src="@/assets/images/productService/fangan2.svg" :alt="$t('productService.solutionTitle')">
                <img class="vrSolutionPlayIcon" src="@/assets/images/productService/play.png" :alt="$t('productService.solutionTitle')">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'vrPage',
        data() {
            return {
                lang: localStorage.getItem('lang'),
            };
        },
        watch: {
            '$i18n.locale'(newLocale) {
                this.lang = newLocale
            }
        },
        mounted(){
           
        },
        methods: {
            
        }
    }
</script>

<style scoped>
    .vrarMaxwidth{
        width: 100%;
        max-width: 1100px;
        box-sizing: border-box;
        padding: 40px 0;
        margin: 0 auto;
    }
    .vrarTitleBox{
        width: 100%;
        padding: 30px 50px;
        background-color: #0041d5;
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 2;
    }
    .vrarProgramme{
        width: calc(100% - 40px);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px 0 0;
        margin: 40px auto;

    }
    
    .vrarRowList{
        width: 450px;
        height: 130px;
        display: flex;
        border-radius: 14px;
        background-color: rgba(218, 244, 253, 0.3);
        box-sizing: border-box;
        padding: 17px;
        position: relative;
    }
    .vrarListIcon{
        width: 60px;
        height: 60px;
        display: block;
        padding-right: 20px;
        position: relative;
        top: -36px;
    }
    .vrarListRight{
        width: calc(100% - 92px);
    }
    .vrarListTitle{
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
    }
    .vrarListDesc{
        width: 100%;
        font-size: 16px;
        color: #333333;
        line-height: 1.6;
    }
    .vrarListDesc.enClass{
        line-height: 1.1;
    }

    .solutionBox{
        width: 100%;
        box-sizing: border-box;
        background-image: linear-gradient(-241.44183657682603deg, #e8f4ff 0%, #ffffff 100%);
        padding-bottom: 60px;
    }
    .solutionBox.last{
        background: #ffffff;
    }
    .solutionMaxWidth{
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        padding: 40px 0;
    }
    .solutionTitle{
        width: 100%;
        text-align: center;
        color: rgba(51, 51, 51, 0.99);
        font-size: 28px;
        font-weight: bold;

    }
    .solutionDesc{
        width: 100%;
        color: #333333;
        text-align: center;
        font-size: 14px;
        line-height: 1.7;
        margin: 30px 0;
    }
    .btnArrBox{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btnList{
        width: 170px;
        height: 50px;
        box-sizing: border-box;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0041d5;
        border-radius: 8px;
        margin: 0 5px;
    }
    .btnIconImg{
        width: 20px;
        height: 20px;
        display: block;
        margin-right: 10px;
    }
    .btnText{
        font-weight: 700;
        color: #ffffff;
    }
    .vrSolutionMaxwidth{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
    }
    .vrSolutionImg{
        width: 100%;
        height: auto;
        display: block;
        transition: all .3s;
    }
    .vrSolutionImg:hover{
        transform: scale(1.03);
    }
    .vrSolutionPlayIcon{
        width: 80px;
        height: 80px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .last .solutionMaxWidth{
        padding-bottom: 0;
    }


    @media only screen and (max-width: 768px) {
        .vrarTitleBox{
            font-size: 14px;
            padding: 30px;
        }
        .vrarMaxwidth{
            padding: 20px 10px;
            max-width: 100%;
        }
        .vrarProgramme{
            margin: 0 auto;
            padding: 0;
            width: 100%;
        }
        .vrarRowList{
            width: 90%;
            height: auto;
            display: flex;
            border-radius: 14px;
            background-color: rgba(218, 244, 253, 0.3);
            box-sizing: border-box;
            padding: 17px;
            position: relative;
            margin: 0 auto;
            margin-top: 40px;
        }
        .vrarListIcon{
            width: 60px;
            height: 60px;
            display: block;
            padding-right: 20px;
            position: relative;
            top: -36px;
        }
        .vrarListDesc{
            font-size: 14px;
        }
        .solutionMaxWidth{
            width: 100%;
            box-sizing: border-box;
            padding: 30px 10px;
        }
        .solutionTitle{
            font-size: 20px;
        }
        .solutionDesc{
            margin: 20px 0 30px;
            box-sizing: border-box;
            padding: 0 10px;
        }
        .btnArrBox{
            flex-wrap: wrap;
        }
        .btnList{
            min-width: 30%;
            width: auto;
            font-size: 14px;
            margin: 5px;
        }
        .btnIconImg{
            margin-right: 5px;
        }
        .vrSolutionMaxwidth{
            padding: 10px;
            box-sizing: border-box;
        }
        .solutionBox{
            padding-bottom: 20px;
        }
    }

</style>