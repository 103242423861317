<template>
    <div class="vrarContent">
        <div class="vrarMaxwidth">
            <div class="vrarTitleBox">
                {{ $t('productService.AiVrarTitle') }}
            </div>
        </div>
        <div class="solutionBox">
            <div class="solutionMaxWidth">
                <div class="solutionTitle">{{ $t('productService.AiSolutionTitle') }}</div>
                <div class="solutionDesc">{{ $t('productService.AiSolutionDesc') }}</div>
            </div>
            <div class="vrSolutionMaxwidth">
                <img class="vrSolutionImg" v-if="lang === 'zh'" src="@/assets/images/productService/aiList1.png" :alt="$t('productService.AiSolutionTitle')">
                <img class="vrSolutionImg" v-else src="@/assets/images/productService/aiList2.png" :alt="$t('productService.AiSolutionTitle')">
            </div>
        </div>
        <div class="aiMaxWidth">
            <div class="aiInfoTitle">{{ $t('productService.aiInfoTitle') }}</div>
            <div class="aiInfoListBox">
                <div class="aiInfoRow">
                    <div class="aiInfoGrid">
                        <img class="aiGridImg" src="@/assets/images/productService/aiInfo1.png" :alt="$t('productService.aiGridTitle1')">
                        <div class="aiGridTitle">{{ $t('productService.aiGridTitle1') }}</div>
                        <div class="aiGridContent">{{ $t('productService.aiGridContent1') }}</div>
                    </div>
                    <div class="aiInfoGrid">
                        <img class="aiGridImg" src="@/assets/images/productService/aiInfo2.png" :alt="$t('productService.aiGridTitle2')">
                        <div class="aiGridTitle">{{ $t('productService.aiGridTitle2') }}</div>
                        <div class="aiGridContent">{{ $t('productService.aiGridContent2') }}</div>
                    </div>
                    <div class="aiInfoGrid">
                        <img class="aiGridImg" src="@/assets/images/productService/aiInfo3.png" :alt="$t('productService.aiGridTitle3')">
                        <div class="aiGridTitle">{{ $t('productService.aiGridTitle3') }}</div>
                        <div class="aiGridContent">{{ $t('productService.aiGridContent3') }}</div>
                    </div>
                </div>
                <div class="aiInfoRow towRow">
                    <div class="aiInfoGrid">
                        <img class="aiGridImg" src="@/assets/images/productService/aiInfo4.png" :alt="$t('productService.aiGridTitle4')">
                        <div class="aiGridTitle">{{ $t('productService.aiGridTitle4') }}</div>
                        <div class="aiGridContent">{{ $t('productService.aiGridContent4') }}</div>
                    </div>
                    <div class="aiInfoGrid">
                        <img class="aiGridImg" src="@/assets/images/productService/aiInfo5.png" :alt="$t('productService.aiGridTitle5')">
                        <div class="aiGridTitle">{{ $t('productService.aiGridTitle5') }}</div>
                        <div class="aiGridContent">{{ $t('productService.aiGridContent5') }}</div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        name: 'vrPage',
        data() {
            return {
                lang: localStorage.getItem('lang'),
            };
        },
        watch: {
            '$i18n.locale'(newLocale) {
                console.log('语言已更改为:', newLocale);
                this.lang = newLocale
            }
        },
        mounted(){
           
        },
        methods: {
            
        }
    }
</script>

<style scoped>
    .vrarContent{
        padding-bottom: 40px;
    }
    .vrarMaxwidth{
        width: 100%;
        max-width: 1100px;
        box-sizing: border-box;
        padding: 40px 0;
        margin: 0 auto;
    }
    .vrarTitleBox{
        width: 100%;
        padding: 30px 35px;
        background-color: #0041d5;
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 2;
    }
    .vrarProgramme{
        width: calc(100% - 40px);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px 0 0;
        margin: 40px auto;

    }
    
    .vrarRowList{
        width: 450px;
        height: 130px;
        display: flex;
        border-radius: 14px;
        background-color: rgba(218, 244, 253, 0.3);
        box-sizing: border-box;
        padding: 17px;
        position: relative;
    }
    .vrarListIcon{
        width: 60px;
        height: 60px;
        display: block;
        padding-right: 20px;
        position: relative;
        top: -36px;
    }
    .vrarListRight{
        width: calc(100% - 92px);
    }
    .vrarListTitle{
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
    }
    .vrarListDesc{
        width: 100%;
        font-size: 16px;
        color: #333333;
        line-height: 1.6;
    }

    .solutionBox{
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 110px;
        background: #ffffff;
    }
    .solutionBox.last{
        background: #ffffff;
    }
    .solutionMaxWidth{
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        padding: 40px 0;
    }
    .solutionTitle{
        width: 100%;
        text-align: center;
        color: rgba(51, 51, 51, 0.99);
        font-size: 28px;
        font-weight: bold;

    }
    .solutionDesc{
        width: 100%;
        color: #333333;
        text-align: center;
        font-size: 14px;
        line-height: 1.7;
        margin: 30px 0;
    }
    .btnArrBox{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btnList{
        width: 170px;
        box-sizing: border-box;
        padding: 14px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0041d5;
        border-radius: 8px;
        margin: 0 5px;
    }
    .btnIconImg{
        width: 20px;
        height: 20px;
        display: block;
        margin-right: 10px;
    }
    .btnText{
        font-weight: 700;
        color: #ffffff;
    }
    .vrSolutionMaxwidth{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
    }
    .vrSolutionImg{
        width: 100%;
        height: auto;
        display: block;
    }
    
    .vrSolutionPlayIcon{
        width: 80px;
        height: 80px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .last .solutionMaxWidth{
        padding-bottom: 0;
    }

    .aiMaxWidth{
        width: 1100px;
        margin: 0 auto;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(194, 194, 194, 0.76);
        background-color: rgba(255, 255, 255, 0.99);
        overflow: hidden;
    }
    .aiInfoTitle{
        width: 100%;
        height: 60px;
        background: #e6e9f2;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(51, 51, 51, 0.9);
        font-size: 22px;
        font-weight: bold;
    }
    .aiInfoListBox{
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
    }
    .aiInfoRow{
        width: 100%;
        display: flex;
        box-sizing: border-box;
        border-bottom: 1px solid #e6e9f2;
    }
    .aiInfoGrid{
        width: 33.33%;
        border-right: 1px solid #e6e9f2;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px;
    }
    .aiInfoGrid:last-child{
        border: none;
    }
    .aiGridImg{
        width: 80px;
        height: 80px;
        display: block;
    }
    .aiGridTitle{
        width: 100%;
        font-weight: 700;
        font-size: 20px;
        color: #333333;
        text-align: center;
        padding: 20px 0;
    }
    .aiGridContent{
        width: 100%;
        color: #333333;
        font-size: 14px;
        text-align: center;
    }
    .towRow{
        border: none;
    }
    .towRow .aiInfoGrid{
        width: 50%;
    }

    @media only screen and (max-width: 768px) {
        .vrarMaxwidth{
            max-width: 100%;
            padding: 20px 10px;
        }
        .vrarTitleBox{
            font-size: 14px;
            padding: 30px;
        }
        .solutionMaxWidth{
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
            padding: 10px;
        }
        .solutionTitle{
            font-size: 20px;
        }
        .solutionDesc{
            margin: 15px 0;
        }
        .vrSolutionMaxwidth{
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
            padding: 0 10px;
        }
        .solutionBox{
            padding-bottom: 30px;
        }
        .aiMaxWidth{
            width: 90%;
            box-sizing: border-box;
        }
        .aiInfoRow{
            flex-wrap: wrap;
            border: none;
        }
        .aiInfoGrid{
            width: 100%;
            padding: 20px 10px;
            border: none;
        }
        .towRow .aiInfoGrid{
            width: 100%;
        }

    }

</style>