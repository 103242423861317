<template>
  <div class="headerBox">
    <img class="header_logo_img" src="@/assets/images/logo.png" alt="CoastalInfo" />
    <div class="header_TabbarBox">
      <div class="tabRow" :class="activeIndex == 0 ? 'tabActive':''" @click="handleSelect(0)">{{$t('header.Tabs1')}}</div>
      <div class="tabRow" :class="activeIndex == 1 ? 'tabActive':''" @click="handleSelect(1)">{{$t('header.Tabs2')}}</div>
      <div class="tabRow" :class="activeIndex == 2 ? 'tabActive':''" @click="handleSelect(2)">{{$t('header.Tabs3')}}</div>
      <el-dropdown @command="changeLang">
        <div class="tabRow">
          <div class="languageBox" v-if="lang === 'zh'">
            <img class="languageIcon" src="@/assets/images/index/zh.png" alt="CoastalInfo/zh/中文" />
            中文
          </div>
          <div class="languageBox" v-else-if="lang === 'en'">
            <img class="languageIcon" src="@/assets/images/index/en.png" alt="CoastalInfo/en/Englash" />
            Englash
          </div>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="zh">
            <div class="languageBox">
              <img class="languageIcon" src="@/assets/images/index/zh.png" alt="CoastalInfo/zh/中文" />
              中文
            </div>
          </el-dropdown-item>
          <el-dropdown-item command="en">
            <div class="languageBox">
              <img class="languageIcon" src="@/assets/images/index/en.png" alt="CoastalInfo/en/Englash" />
              Englash
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="lineBox" :style="'left:'+ (activeIndex * 140 + 35) +'px'"></div>
    </div>
    <div class="header_moble_Tab">
      <el-popover placement="bottom" trigger="click">
        <div class="mobileTabBox">
          <div class="mobileTabRow" :class="activeIndex == 0 ? 'tabActive':''" @click="handleSelect(0)">{{$t('header.Tabs1')}}</div>
          <div class="mobileTabRow" :class="activeIndex == 1 ? 'tabActive':''" @click="handleSelect(1)">{{$t('header.Tabs2')}}</div>
          <div class="mobileTabRow" :class="activeIndex == 2 ? 'tabActive':''" @click="handleSelect(2)">{{$t('header.Tabs3')}}</div>
          <div class="mobileTabRow" @click="changeLang(lang=='zh'?'en':'zh')">
            <span :class="lang == 'zh' ? 'tabActive':''">中文</span> / <span :class="lang == 'en' ? 'tabActive' :''">English</span>
          </div>
        </div>
        <el-button plain icon="el-icon-s-unfold" slot="reference"></el-button>
      </el-popover>
    </div>
  </div>
</template>

<script>

export default {
  name: "headerPage",
  props: {
    msg: String,
  },
  data() {
    return {
      activeIndex: 0,
      lang: 'zh',
    };
  },
  mounted () {
    // 获取顶部导航下标
    this.activeIndex = localStorage.getItem('activeIndex');
    if(localStorage.getItem('lang')){
      this.lang = localStorage.getItem('lang');
    }else{
      localStorage.setItem('lang', 'zh')
      this.lang = 'zh'
    }
  },
  methods: {
    handleSelect(index) {
      if(this.activeIndex == index) return
      const routesPath = this.$router.options.routes
      this.activeIndex = index
      // 存储顶部导航下标
      localStorage.setItem('activeIndex', index);
      this.$router.push({ path: routesPath[index].path });
      // 切换页面后滚动条置于顶部
      const element = document.getElementById("pageMain");
      element.scrollTop = 0;
    },
    // 切换语言
    changeLang (val) {
      this.$nextTick(() => {
        localStorage.setItem('lang', val)
        this.lang = val
        this.$i18n.locale = val
      })
    }

  },
};
</script>

<style scoped>
  .headerBox{
    width: 100%;
    max-width: 1200px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }
  .header_logo_img{
    width: 154px;
    height: 52px;
  }
  .header_TabbarBox{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .header_moble_Tab{
    display: flex;
  }
  .tabRow{
    width: 140px;
    height: 64px;
    font-size: 16px;
    color: rgba(51, 51, 51, 0.7);
    cursor: pointer;
    transition: all .3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tabRow.tabActive{
    color: #000000;
    font-weight: 400;
  }
  .lineBox{
    width: 70px;
    height: 3px;
    background: #409EFF;
    transition: all .3s;
    position: absolute;
    bottom: 0;
  }
  .languageBox{
    display: flex;
    align-items: center;
  }
  .languageIcon{
    width: 30px;
    height: 20px;
    display: block;
    margin-right: 5px;
  }

  @media screen and (min-width: 768px) {
    .header_TabbarBox{
      display: flex;
    }
    .header_moble_Tab{
      display: none;
    }
  }
  /* 移动端 */
  @media only screen and (max-width: 768px) {
    .headerBox{
      height: auto;
      padding: 10px;
      box-sizing: border-box;
    }
    .header_TabbarBox{
      display: none;
    }
    .header_moble_Tab{
      display: inline;
    }
    .header_moble_Tab .el-button{
      padding: 8px 10px;
      font-size: 20px;
    }
    .mobileTabBox{
      width: 100%;

    }
    .mobileTabRow{
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: rgba(51, 51, 51, 0.7);
      padding: 6px 0;
    }
    .mobileTabRow.tabActive{
      color: #409EFF;
    }
    .mobileTabRow .tabActive{
      color: #409EFF;
    }
  }
  
</style>
