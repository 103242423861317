
export default {
    header:{
        Tabs1: 'HOME',
        Tabs2: 'Product Service',
        Tabs3: 'About Us',
    },
    footer: {
        title1: 'Contact Us',
        usCooperate1: 'Business Commercial: business@coastalinfo.cn',
        usCooperate2: 'Consultation: 86-138-7664-4296',
        usCooperate3: 'Investment cooperation: business@coastalinfo.cn',
        usCooperate4: 'Media: business@coastalinfo.cn',
        title2: 'Follow Us',
        usCooperate5: 'Public account: Coastal Information',
        usQrCodeImg: 'Coastal Information',
        copyright: 'Guangdong ICP No. 14050408-3 Copyright © 2014-2017 Zhuhai Coastal Information Technology Co., Ltd. All rights reserved',
    },
    index:{
        carousel1: 'Immersive Boundless  Unlocking the Future  Transcending Reality',
        carousel2: 'Coastal Intelligence and Innovation, Empowering the Future',
        carousel3: 'Constantly innovating, forging ahead with determination.',
        carousel4: 'XR Technology: Expanding Infinite Possibilities.',
        solution: {
            grid1: { 
                test: 'Technological innovation',
                dose: 'Advancing the development of VR, AR, and AI technology',
            },
            grid2: { 
                test: 'Business Development',
                dose: 'Extensive market newwork and business development capabilities',
            },
            grid3: { 
                test: 'Industry experience.',
                dose: 'Accumulated extensive application experience in multiple fields.',
            },
            grid4: { 
                test: 'Customer service',
                dose: 'High-quality customized solutions and after-sales support.',
            },
        },
        solutionConetnt:{
            title: 'Expertizing in solutions business',
            titleEn: 'SOLUTION BUSINESS',
            desc: 'Coastal Information Technology Co., Ltd. possesses exceptional professional technical capabilities, enabling the development of cutting-edge VR, AR, and AI solutions. The company offers customized services across various industries and enhances customer business efficiency through system integration and optimization, while ensuring the long-term stability and upgrade of technical systems.',
        },
        techInfo: {
            leftTitle: 'Next-generation immersive technology.',
            leftTitleEn: 'NEW GENERATION',
            leftDesc: 'XR technology, with its unique immersive experience, diverse application scenarios, and enhanced interactivity, is revolutionizing the way users interact with digital content. It can be flexibly customized to meet various needs, driving innovation and efficiency across industries from entertainment to industrial applications. As a representative of cutting-edge technology, XR not only blurs the boundaries between virtual and real but also creates limitless possibilities for future digital transformation.',
            rightTips1: 'Next-generation',
            rightTips2: 'Immersive technology',
        },
        businessArea: {
            title: 'BUSINESS AREA',
            titleMore: 'Learn More',
            subtitle: 'Business Area',
            areaTopText1: 'VR intelligent training',
            areaRowContent1: 'By Utilizingvirtual reality technology to create realistic simulated environments, it provides interactive and immersive learning experiences. Combined with data analysis and intelligent feedback, this approach enhances training effectiveness and skill mastery.',
            areaTopText2: 'AR scene interaction',
            areaRowContent2: 'By leveraging augmented reality (AR) technology, virtual elements are integrated into the real environment, enabling users to interact with virtual content in an immersive manner, thereby enhancing the sense of presence and engagement in the experience.',
            areaTopText3: 'AI intelligent applications',
            areaRowContent3: 'Empowering nursing institutions with information technology, AI-driven intelligent interactions provide online care consultations, information analysis, and health profiling services. This enables the creation of personalized, professional, and real-time care plans, supporting long-term rehabilitation and care services',
            business_title: 'SOLUTION',
            business_subtitle: 'Intelligent solution', 
            business_areaTopText1: 'Self-developed deep learning framework', 
            business_content1: 'The company has independently developed an advanced deep learning framework, enhancing technological autonomy and enabling the provision of efficient, customized XR solutions, thereby boosting market competitiveness.', 
            business_areaTopText2: 'Professional research and development team.', 
            business_content2: 'We have an experienced R&D team focused on innovating and developing cutting-edge technologies, ensuring the company remains at the forefront of ( which will substainably ensure our competeness in) tech industry.',
            business_areaTopText3: 'Data-driven precise decision-making.', 
            business_content3: 'By leveraging big data analysis capabilities, Coastal Information can provide customers with accurate business decision-making support, optimize operational efficiency, and promote intelligent transformation.',
            business_areaTopText4: 'Agile innovation capabilities of the internet.', 
            business_content4: 'The company demonstrates exceptional innovation and agility in the internet sector, allowing it to quickly respond to market changes and promptly launch innovative products and services, thus maintaining a competitive advantage in the industry.',
        },
    },
    
    productService: {
        vrArTab1: 'VR intelligent training',
        vrArTab2: 'AR Scene interaction',
        vrArTab3: 'AI Application',
        vrarTitleBox: 'VR technology is transforming the training industry by providing highly immersive simulated environments that offer a more realistic and interactive learning experience. In pilot and nursing training, VR simulates cockpit and hospital settings, allowing learners to enhance their skills and responsiveness in a safe virtual space, significantly improving training effectiveness and efficiency.',
        vrarListTitle1: 'Immersive Learning',
        vrarListDesc1: 'Through VR technology, learners can engage in immersive nursing practice exercises, enhancing the effectiveness of their training.',
        vrarListTitle2: 'Interactive Learning',
        vrarListDesc2: 'Enhancing practical experience through virtual simulations.',
        vrarListTitle3: 'Security Simulation',
        vrarListDesc3: 'Providing realistic environments through virtual reality technology to safely train learners in skills for handling dangerous situations.',
        vrarListTitle4: 'Rich resources',
        vrarListDesc4: 'Diverse learning resources that support learning anytime, anywhere.',
        solutionTitle: 'VR Immersive Intelligent Flight Training Solution',
        solutionDesc: 'Create a highly realistic virtual environment that reproduces the physical environment and weather conditions of real flight, provide opportunities for repeated practice, and enable pilots to become familiar with complex operations in a safe virtual environment, reducing the risks and costs of actual training.',
        solutionBtn1: 'Efficient',
        solutionBtn2: 'Flexible',
        solutionBtn3: 'No risk',
        solutionBtn4: 'Personalization',
        solutionBtn5: 'Low cost',
        solutionTitle2: 'VR Immersive Intelligent Nursing Training Solution',
        solutionDesc2: 'Using immersive virtual reality technology to simulate real nursing scenarios, we can accurately improve the skills and emergency response capabilities of nursing staff and provide more efficient and safe training solutions for the medical industry.',
        // 
        ArSolutionTitle: 'AR Immersive Scene Interaction Game Engine',
        ArSolutionDesc: 'Leveraging augmented reality technology, it seamlessly integrates virtual elements with the real world to create an immersive interactive experience for players. The game transcends traditional screen limitations, allowing players to engage in real-world environments, delivering an unprecedented sense of immersion and enjoyment.',
        ArSolutionBtn1: 'Immersive virtual world',
        ArSolutionBtn2: 'Personalized dynamic feedback',
        ArSolutionBtn3: 'Highly interactive gaming experience',
        ArSolutionTitle2: 'Immersive VR adventure',
        ArSolutionSubtitle: 'Bringing you a unique gaming experience!',
        ArSolutionDesc2: 'VR immersive scene interaction games offer a strong sense of immersion through highly realistic virtual environments and free interactive experiences, delivering engagement and realism unmatched by traditional games. This combination enhances entertainment while improving player focus and learning outcomes, making virtual experiences more vivid, personalized, and challenging.',
        // 
        AiVrarTitle: 'AI technology is empowering the nursing industry by enabling precise data processing and decision support through intelligent platforms. This optimizes the quality of care services, enhances resource utilization efficiency, and drives the digital transformation of nursing services.',
        AiSolutionTitle: 'Artificial Intelligence-powered smart nursing solutions.',
        AiSolutionDesc: 'The AI + Nursing platform empowers healthcare institutions by building a knowledge base and language model for the nursing industry, leveraging information technology to deliver personalized, professional, and real-time care services. Additionally, through AI-driven interactions (Nurse-Chat) and multidimensional data analysis, it generates health information profiles to support the development and implementation of long-term professional care plans.',
        aiInfoTitle: 'Advantages and Enhancement of Intelligent Nursing',
        aiGridTitle1: 'Improve service satisfaction',
        aiGridContent1: 'Improve the satisfaction of patients and service recipients through personalized care and efficient services.',
        aiGridTitle2: 'Rapid implementation of application iteration',
        aiGridContent2: 'The nursing evaluation and plan of the neural node knowledge base operate independently, supporting the rapid launch of specific nursing services and continuous optimization.',
        aiGridTitle3: 'Improvement of nursing professional competence',
        aiGridContent3: 'Provide precise decision-making assistance and training, significantly enhancing the professional skills of nursing staff.',
        aiGridTitle4: 'Human resource demand optimization',
        aiGridContent4: 'By implementing intelligent scheduling and automated processes, we can reduce reliance on human resources and improve resource allocation efficiency.',
        aiGridTitle5: 'Enhancement of business expansion capability',
        aiGridContent5: 'Through intelligent and data-driven analysis, help nursing institutions expand their business scope faster.',
    },

    aboutUs: {
        aboutUsTitle: 'Coastal Intelligence and Innovation, Empowering the Future',
        aboutUsSubtitle: 'COMPANY PROFILE',
        aboutUsParagraph1: 'Zhuhai Coastal Information Technology Co., Ltd. was established in 2014. It passed the national high-tech enterprise certification in 2017 and owns more than 40 software copyrights. It is the Zhuhai software cooperation R&D center of Mason Bodoli (Silicon Valley) and excels in application software design and development in technical fields such as AI, XR, and WEB3.0 (blockchain).',
        aboutUsParagraph2: 'The core members of the company come from the software development team of a well-known domestic software development company. They have nearly 20 years of experience in software cooperation and development and a mature R&D architecture. They have successfully completed many large-scale Internet, mobile Internet, blockchain and enterprise resource information management (ERP) software projects. Familiar fields include civil aviation pilot training, civil aviation aircraft inspection and aviation material management, nursing and elderly care professional evaluation and information management, immersive AR script games and other fields. The clients served include Waltonchain, China Mobile, China Telecom, Sinotrans, China Southern Airlines, major newspaper groups across the country, large retail chain supermarkets in Hong Kong and Macao, large optical chain companies, large pharmaceutical chain companies and Apple and other large domestic and foreign companies.',
        aboutUsSubtitle2: 'Integrate cutting-edge technology to promote intelligent transformation.',
        aboutUsParagraph3: 'We are doing our best to become a professional leader in the application of AI and AR technology, and to create new business value and experience for users by integrating AI and virtual reality technology.',
        ideaList1: 'We continuously create new experiences and value, opening up endless possibilities through a deep understanding of customer needs and technical capabilities.',
        ideaList2: 'We are committed to innovating services and business, always putting customers and partners first, and winning trust with high-quality products.',
        ideaList3: 'We create future value together through innovation and collaboration.',
        ideaList4: 'We hope to support talents with dreams and passions and grow together with business partners.',
        ideaList5: 'As a member of the new technology era, Coastal Information will continue to drive change through cutting-edge technologies, innovative services and solutions that meet customer needs.',
        ideaList6: 'We look forward to your continued attention and support as we work together to meet the new challenges and opportunities in the Internet field and embrace the possibilities of the future.',
        aboutUsTitle1: 'WELECOME TO COOPERATE',
        aboutUsSubtitle1: 'WELECOME TO COOPERATE',
        welecomeContentList1: 'Join us in creating a new chapter in the era of intelligence and digitalization.',
        welecomeContentList2: 'Coastal, your reliable partner, helps you achieve higher goals.',
        contactTitle1: 'Business Phone No.',
        contactSubtitle1: '86-756-8818398',
        contactTitle2: 'E-MAIL',
        contactSubtitle2: 'business@coastalinfo.cn',
        contactTitle3: 'Telephone',
        contactSubtitle3: '86-138-7664-4296',
        contactTitle4: 'Company Address',
        contactSubtitle4: 'SY6-1501 Aoyuan park，Zhuhai',
        contactSubtitle4_1: 'Guangdong，China',
    }
    
    
  }