<template>
    <div class="pageContentBox">
        <!-- 轮播图 -->
        <div class="switchBox">
            <el-carousel trigger="click" :interval="4000" ref="slideCarousel" @change="switchChange">
                <el-carousel-item>
                    <div class="pageTopBox bg1">
                        <div class="switchMaxWidth">
                            <img class="switchRow" :class="switchIndex == 0 ? 'switchActive':''" src="@/assets/images/switch/banner1.png" :alt="$t('index.carousel1')" />
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="pageTopBox bg2">
                        <div class="switchMaxWidth">
                            <img class="switchRow" :class="switchIndex == 1 ? 'switchActive':''" src="@/assets/images/switch/banner2.png" :alt="$t('index.carousel2')" />
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="pageTopBox bg3">
                        <div class="switchMaxWidth">
                            <img class="switchRow" :class="switchIndex == 2 ? 'switchActive':''" src="@/assets/images/switch/banner3.png" :alt="$t('index.carousel3')" />
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="pageTopBox bg4">
                        <div class="switchMaxWidth">
                            <img class="switchRow" :class="switchIndex == 3 ? 'switchActive':''" src="@/assets/images/switch/banner4.png" :alt="$t('index.carousel4')" />
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 解决方案 -->
        <div class="solutionBox">
            <div class="gridSolutMax">
                <div class="solutionGridBox">
                    <div class="gridColumn ftBox">
                        <div class="gridList">
                            <img class="gridIcon" src="@/assets/images/index/icon1.png" :alt="$t('index.solution.grid1.test')">
                            <div class="gridContent">
                                <div class="gridText">{{$t('index.solution.grid1.test')}}</div>
                                <div class="gridDosc">{{$t('index.solution.grid1.dose')}}</div>
                            </div>
                        </div>
                        <div class="gridList">
                            <img class="gridIcon" src="@/assets/images/index/icon2.png" :alt="$t('index.solution.grid2.test')">
                            <div class="gridContent">
                                <div class="gridText">{{$t('index.solution.grid2.test')}}</div>
                                <div class="gridDosc">{{$t('index.solution.grid2.dose')}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="gridColumn">
                        <div class="gridList">
                            <img class="gridIcon" src="@/assets/images/index/icon3.png" :alt="$t('index.solution.grid3.test')">
                            <div class="gridContent">
                                <div class="gridText">{{$t('index.solution.grid3.test')}}</div>
                                <div class="gridDosc">{{$t('index.solution.grid3.dose')}}</div>
                            </div>
                        </div>
                        <div class="gridList">
                            <img class="gridIcon" src="@/assets/images/index/icon4.png" :alt="$t('index.solution.grid4.test')">
                            <div class="gridContent">
                                <div class="gridText">{{$t('index.solution.grid4.test')}}</div>
                                <div class="gridDosc">{{$t('index.solution.grid4.dose')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 专业技术能力 -->
                <div class="solutionContentBox">
                    <div class="solutionContentTitle">{{$t('index.solutionConetnt.title')}}</div>
                    <div class="solutionContentTitleEn">{{$t('index.solutionConetnt.titleEn')}}</div>
                    <div class="solutionContentDesc">{{$t('index.solutionConetnt.desc')}}</div>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="techInfoBox">
            <div class="maxWidth">
                <div class="techInfoLeft">
                    <div class="solutionContentTitle">{{$t('index.techInfo.leftTitle')}}</div>
                    <div class="solutionContentTitleEn">{{$t('index.techInfo.leftTitleEn')}}</div>
                    <div class="solutionContentDesc">{{$t('index.techInfo.leftDesc')}}</div>
                </div>
                <div class="techInfoRight">
                    <div class="techInfoTipsBox">
                        <div>{{$t('index.techInfo.rightTips1')}}</div>
                        <div>{{$t('index.techInfo.rightTips2')}}</div>
                    </div>
                    <div class="techInfoImgBox">
                        <img class="techInfoImg1" src="@/assets/images/index/img1.png" :alt="$t('index.techInfo.leftTitle')">
                    </div>
                    <div class="techImgBg"></div>
                    <div class="blueBlock left"></div>
                    <div class="blueBlock right"></div>
                </div>
            </div>
        </div>
        <!-- BUSINESS AREA -->
        <div class="businessAreaBox">
            <div class="businessMaxWidth">
                <div class="businessTopBox">
                    <div class="businessTitleText">{{$t('index.businessArea.title')}}</div>
                    <div class="businessMore">{{$t('index.businessArea.titleMore')}}</div>
                </div>
                <div class="subtitleText">{{$t('index.businessArea.subtitle')}}</div>
                <div class="subtitleAreaList">
                    <div class="areaRow">
                        <div class="areaRowTopBox">
                            <img class="areaTopIcon" src="@/assets/images/index/icon5.png" :alt="$t('index.businessArea.areaTopText')">
                            <div class="areaTopText">{{$t('index.businessArea.areaTopText1')}}</div>
                        </div>
                        <div class="areaRowContentBox" :class=" lang === 'en' ? 'isEnglish' : '' ">
                            {{$t('index.businessArea.areaRowContent1')}}
                        </div>
                    </div>
                    <div class="areaRow">
                        <div class="areaRowTopBox">
                            <img class="areaTopIcon" src="@/assets/images/index/icon6.png" alt="AR场景互动">
                            <div class="areaTopText">{{$t('index.businessArea.areaTopText2')}}</div>
                        </div>
                        <div class="areaRowContentBox" :class=" lang === 'en' ? 'isEnglish' : '' ">
                            {{$t('index.businessArea.areaRowContent2')}}
                        </div>
                    </div>
                    <div class="areaRow">
                        <div class="areaRowTopBox">
                            <img class="areaTopIcon" src="@/assets/images/index/icon7.png" alt="AI智能应用">
                            <div class="areaTopText">{{$t('index.businessArea.areaTopText3')}}</div>
                        </div>
                        <div class="areaRowContentBox" :class=" lang === 'en' ? 'isEnglish' : '' ">
                            {{$t('index.businessArea.areaRowContent3')}}
                        </div>
                    </div>
                </div>
            </div>
            <!-- 智能方案 -->
            <div class="businessMaxWidth">
                <div class="businessTopBox">
                    <div class="businessTitleText">{{$t('index.businessArea.business_title')}}</div>
                </div>
                <div class="subtitleText solution">{{$t('index.businessArea.business_subtitle')}}</div>
                <div class="subtitleAreaList">
                    <div class="solutionList">
                        <div class="solutionTopBox">
                            <img class="solutionTopIcon" src="@/assets/images/index/solution1.png" :alt="$t('index.businessArea.business_areaTopText1')">
                        </div>
                        <div class="solutionRowTitle" :class="lang === 'en' ? 'minHeight' : ''">{{$t('index.businessArea.business_areaTopText1')}}</div>
                        <div class="solutionRowDesc">{{$t('index.businessArea.business_content1')}}</div>
                    </div>
                    <div class="solutionList">
                        <div class="solutionTopBox">
                            <img class="solutionTopIcon" src="@/assets/images/index/solution2.png" :alt="$t('index.businessArea.business_areaTopText2')">
                        </div>
                        <div class="solutionRowTitle" :class="lang === 'en' ? 'minHeight' : ''">{{$t('index.businessArea.business_areaTopText2')}}</div>
                        <div class="solutionRowDesc">{{$t('index.businessArea.business_content2')}}</div>
                    </div>
                    <div class="solutionList">
                        <div class="solutionTopBox">
                            <img class="solutionTopIcon" src="@/assets/images/index/solution3.png" :alt="$t('index.businessArea.business_areaTopText3')">
                        </div>
                        <div class="solutionRowTitle" :class="lang === 'en' ? 'minHeight' : ''">{{$t('index.businessArea.business_areaTopText3')}}</div>
                        <div class="solutionRowDesc">{{$t('index.businessArea.business_content3')}}</div>
                    </div>
                    <div class="solutionList">
                        <div class="solutionTopBox">
                            <img class="solutionTopIcon" src="@/assets/images/index/solution4.png" :alt="$t('index.businessArea.business_areaTopText4')">
                        </div>
                        <div class="solutionRowTitle" :class="lang === 'en' ? 'minHeight' : ''">{{$t('index.businessArea.business_areaTopText4')}}</div>
                        <div class="solutionRowDesc">{{$t('index.businessArea.business_content4')}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <footerPage />
    </div>
</template>

<script>
    import footerPage from '@/components/footerPage.vue';
    export default {
        name: 'homeIndex',
        components: {
            footerPage
        },
        data() {
            return {
                lang: localStorage.getItem('lang'),
                switchIndex: 0,
            };
        },
        watch: {
            '$i18n.locale'(newLocale) {
                console.log('语言已更改为:', newLocale);
                this.lang = newLocale
            }
        },
        mounted(){
            
        },
        methods:{
            // 轮播图切换
            switchChange(index){
                this.switchIndex = index
            },
        }
    }
</script>

<style scoped>
    .pageContentBox{
        width: 100%;
        box-sizing: border-box;
    }
    .switchBox{
        width: 100%;
    }
    .switchBox /deep/ .el-carousel__container{
        height: 500px!important;
    }
    .pageTopBox{
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center center;
    }
    .bg1{
        background-image: url(../../assets/images/switch/banner1_bg.jpg);
    }
    .bg2{
        background-image: url(../../assets/images/switch/banner2_bg.jpg);
    }
    .bg3{
        background-image: url(../../assets/images/switch/banner3_bg.jpg);
    }
    .bg4{
        background-image: url(../../assets/images/switch/banner4_bg.jpg);
    }
    .switchMaxWidth{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        position: relative;
    }
    .switchRow{
        width: 1434px;
        height: 448px;
        object-fit: cover;
        display: block;
        transform: translateX(-50%);
        transition: all .3s;
        position: absolute;
        top: 25px;
        left: -25%;
    }
    .switchActive{
        animation: switchShow 1s ease 1 forwards; /* 动画名称、持续时间、缓动函数、循环次数 */
    }
    @keyframes switchShow {
        0% {
            transform: translateX(-50%); /* 初始位置 */
        }
        100% {
            transform: translateX(0); /* 回到初始位置 */
        }
    }

    .switchBox /deep/.el-carousel__indicator--horizontal .el-carousel__button {
        width: 10px;
        height: 10px;
        background: #f2f2f2;
        border: 1px solid #ffffff;
        border-radius: 50px;
        opacity: 0.8;
        border: none !important;
    }
    .switchBox /deep/.el-carousel__indicator--horizontal.is-active .el-carousel__button {
        width: 20px;
        height: 10px;
        background: #409eff;
        border-radius: 40px;
        border: none !important;
        opacity: 1;
    }
    .solutionBox{
        width: 100%;
        background: url(../../assets/images/index/bg1.png) no-repeat no-repeat;
    }
    .gridSolutMax{
        max-width: 1100px;
        margin: 0 auto;
        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 37px 0;
    }
    .solutionGridBox{
        margin-right: 30px;
        position: relative;
        display: flex;
        align-items: center;
    }
    .gridColumn{
       margin-right: 30px;
    }
    .gridColumn.ftBox{
       position: relative;
       top: -100px;
       left: 0;
       z-index: 9;
    }
    .gridList{
        width: 280px;
        height: 250px;
        padding: 20px;
        box-sizing: border-box;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
        transition: all .3s;
        position: relative;
        top: 0;
    }
    .gridList:first-child{
        margin-bottom: 30px;
    }
    .gridList:hover{
        top: -10px;
    }
    .gridIcon{
        width: 66px;
        height: 66px;
    }
    .gridText{
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        color: #000000;
        margin: 20px 0;
    }
    .gridDosc{
        width: 100%;
        color: #909399;
        font-size: 16px;
        line-height: 26px
    }
    /*  */
    .solutionContentTitle{
        font-weight: 700;
        color: #000000;
        font-size: 28px;
    }
    .solutionContentTitleEn{
        color: #909399;
        font-size: 20px;
        padding: 20px 0;
    }
    .solutionContentDesc{
        color: rgba(51, 51, 51, 0.9);
        font-size: 16px;
        line-height: 2;
    }
    /*  */
    .techInfoBox{
        width: 100%;
        /* height: 642px; */
        background: url(../../assets/images/index/bg2.png) no-repeat no-repeat;
    }
    .maxWidth{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 40px 0 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .techInfoLeft{
        width: 583px;

    }
    .techInfoRight{
        width: 350px;
        position: relative;
    }
    .techInfoImgBox{
        width: 350px;
        height: 438px;
        overflow: hidden;
        position: relative;
        z-index: 8;
    }
    .techInfoImg1{
        width: 350px;
        height: 438px;
        transition: all .2s;
    }
    .techInfoImg1:hover{
        transform: scale(1.05);
    }
    .techImgBg{
        width: 350px;
        height: 438px;
        background-image: linear-gradient(-180deg, #4672a2 2.423788105945041%, #0041d5 91.67916041978903%);
        position: absolute;
        bottom: -30px;
        right: -30px;
        z-index: 7;
    }
    .techInfoTipsBox{
        width: 140px;
        height: 127px;
        background-image: linear-gradient(-180deg, #00b2ff 0%, #003eff 100%);
        box-sizing: border-box;
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #ffffff;
        position: absolute;
        top: 40px;
        left: -100px;
        z-index: 9;
        opacity: 0.82;
    }
    .blueBlock{
        width: 20px;
        height: 20px;
        opacity: 0.58;
        background-image: linear-gradient(-180deg, #00b2ff 0%, #003eff 100%);
        position: absolute;
        z-index: 8;
    }
    .blueBlock.right{
        bottom: 0;
        right: -70px;
    }
    .blueBlock.left{
        top: 160px;
        left: -140px;
    }
    /*  */
    .businessAreaBox{
        width: 100%;
        background: url(../../assets/images/index/bg1.png) no-repeat no-repeat;
        background-position: right bottom;
    }
    .businessMaxWidth{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 60px 0;
    }
    .businessTopBox{
        width: 100%;
        position: relative;
    }
    .businessTitleText{
        width: 100%;
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        color: rgba(51, 51, 51, 0.99);
    }
    .businessMore{
        padding: 5px 20px;
        font-size: 16px;
        color: #0085ff;
        background: #ecf6fe;
        border-radius: 16px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
    .businessMore:hover{
        opacity: 0.8;
    }
    .subtitleText{
        width: 100%;
        text-align: center;
        color: #909399;
        padding: 20px 0 40px;
    }
    .subtitleAreaList{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .areaRow{
        width: calc(33.33% - 20px);
        height: 380px;
        border-radius: 20px;
        background-color: #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        transition: all .3s;
        position: relative;
    }
    .areaRow:hover{
        transform: scale(1.03);
    }
    .areaRowTopBox{
        width: 100%;
        padding: 30px 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url(../../assets/images/index/areaBg.svg) no-repeat no-repeat;
        background-size: 100% 100%;
    }
    .areaTopIcon{
        width: 60px;
        height: 60px;
        display: block;
    }
    .areaTopText{
        font-weight: 700;
        color: #ffffff;
        font-size: 24px;
        margin-left: 30px;
    }
    .areaRowContentBox{
        width: 100%;
        height: calc(100% - 120px);
        padding: 25px 35px;
        box-sizing: border-box;
        color: rgba(51, 51, 51, 0.9);
        font-size: 16px;
        line-height: 2;
    }
    .isEnglish{
        line-height: normal;
    }

    /* 智能方案 */
    .solution{
        padding-bottom: 20px;
    }
    .solutionList{
        width: calc(25% - 40px);

    }
    .solutionTopBox{
        width: 100%;
        padding: 60px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .solutionTopIcon{
        width: 100px;
        height: 100px;
        display: block;
    }
    .solutionRowTitle{
        width: 100%;
        text-align: center;
        font-weight: 700;
        color: #333333;
        font-size: 20px;
        margin-bottom: 25px;
    }
    .minHeight{
        min-height: 78px;
    }
    .solutionRowDesc{
        width: 100%;
        color: rgba(51, 51, 51, 0.9);
        font-size: 16px;
        line-height: 1.7;
    }


    
    /* 移动端 */
    @media only screen and (max-width: 1600px){
        .pageTopBox{
            background-size: 100% 100%;
        }
    }
    @media only screen and (max-width: 768px) {
        .switchBox /deep/ .el-carousel__container{
            height: 200px!important;
        }
        .switchRow{
            width: auto;
            height: 200px;
            object-fit: cover;
            display: block;
            transform: translateX(-50%);
            transition: all .3s;
            position: absolute;
            top: 0;
            left: -20%;
        }
        .pageTopBox{
            background-size: 100% 100%;
        }
        

        .solutionBox{
            width: 100%;
            background: url(../../assets/images/index/bg1.png) no-repeat no-repeat;
            background-size: 100% 100%;
        }
        .gridSolutMax{
            width: 100%;
            padding: 20px 0;
            flex-direction: column;
        }
        .solutionGridBox{
            margin-right: 0;
            flex-direction: column;
            width: 100%;
            margin-bottom: 20px;
        }
        .gridColumn{
            margin-right: 0;
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
        }
       
        .gridColumn.ftBox{
            position: relative;
            top: 0;
        }
        .gridList{
            width: 100%;
            height:auto;
            padding: 10px;
            margin-bottom: 10px;
            flex-direction: row;
            align-items: center;
        }
        .gridList:first-child{
            margin-bottom: 10px;
        }
        .gridList:hover{
            top: 0;
        }
        .gridIcon{
            width: 66px;
            height: 66px;
        }
        .gridContent{
            width: calc(100% - 66px);
            box-sizing: border-box;
            padding-left: 10px;
        }
        .gridText{
            width: 100%;
            font-size: 16px;
            font-weight: 700;
            color: #000000;
            margin: 10px 0;
        }
        .gridDosc{
            width: 100%;
            color: #909399;
            font-size: 14px;
            line-height: 1.7
        }
        .solutionContentBox{
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;
        }
        .solutionContentTitle{
            font-size: 20px;
            text-align: center;
        }
        .solutionContentTitleEn{
            text-align: center;
            font-size: 14px;
            padding: 10px 0;
        }
        .solutionContentDesc{
            font-size: 14px;
        }
        /*  */
        .techInfoBox{
            width: 100%;
            background: url(../../assets/images/index/bg2.png) no-repeat no-repeat;
        }
        .maxWidth{
            width: 100%;
            box-sizing: border-box;
            padding: 10px;
            flex-direction: column;
        }
        .techInfoLeft{
           width: 100%;
           margin-bottom: 10px;
        }
        .techInfoRight{
            width: 100%;
            position: relative;
        }
        .techInfoImgBox{
            width: 100%;
            height: auto;
            overflow: hidden;
            position: relative;
            z-index: 8;
        }
        .techInfoImg1{
            width: 100%;
            height: auto;
            transition: all .2s;
        }
        .techImgBg{
            width: 100%;
            height: 438px;
            background-image: linear-gradient(-180deg, #4672a2 2.423788105945041%, #0041d5 91.67916041978903%);
            position: absolute;
            bottom: -6px;
            right: -6px;
            z-index: 7;
        }
        .techInfoTipsBox{
            display: none;
        }
        .blueBlock{
            display: none;
        }
        
        /*  */
        .businessAreaBox{
            width: 100%;
        }
        .businessMaxWidth{
            width: 100%;
            box-sizing: border-box;
            padding: 40px 10px;
        }
        .businessMaxWidth:last-child{
            padding: 10px;
        }
        .businessTitleText{
            font-size: 26px;
        }
        .businessMore{
            display: none;
        }
        .businessMore:hover{
            opacity: 0.8;
        }
        .subtitleText{
            width: 100%;
            text-align: center;
            color: #909399;
            padding: 20px 0 40px;
        }
        .subtitleAreaList{
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        .areaRow{
            width: 100%;
            height: auto;
            margin-bottom: 20px;
        }
        .areaRow:last-child{
           margin: 0;
        }
        .areaRowTopBox{
            width: 100%;
            padding: 30px 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url(../../assets/images/index/areaBg.svg) no-repeat no-repeat;
            background-size: 100% 100%;
        }
        .areaTopIcon{
            width: 60px;
            height: 60px;
            display: block;
        }
        .areaTopText{
            font-weight: 700;
            color: #ffffff;
            font-size: 24px;
            margin-left: 30px;
        }
        .areaRowContentBox{
            width: 100%;
            height: auto;
            padding: 25px 35px;
            box-sizing: border-box;
            color: rgba(51, 51, 51, 0.9);
            font-size: 14px;
            line-height: 2;
        }
        /* 智能方案 */
        .solution{
            padding-bottom: 10px;
        }
        .solutionList{
            width: 100%;

        }
        .solutionTopBox{
            width: 100%;
            padding: 60px 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .solutionTopIcon{
            width: 100px;
            height: 100px;
            display: block;
        }
        .solutionRowTitle{
            width: 100%;
            text-align: center;
            font-weight: 700;
            color: #333333;
            font-size: 20px;
            margin-bottom: 25px;
        }
        .solutionRowDesc{
            width: 100%;
            color: rgba(51, 51, 51, 0.9);
            font-size: 16px;
            line-height: 1.7;
        }
        
        
    }


</style>