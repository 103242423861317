
export default {
    header:{
        Tabs1: '首页',
        Tabs2: '产品服务',
        Tabs3: '关于我们',
    },
    footer: {
        title1: '联系我们',
        usCooperate1: '商务合作：business@coastalinfo.cn',
        usCooperate2: '合作咨询：86-138-7664-4296',
        usCooperate3: '投资合作：business@coastalinfo.cn',
        usCooperate4: '媒体合作：business@coastalinfo.cn',
        title2: '关注我们',
        usCooperate5: '公众号：沿海信息',
        usQrCodeImg: '沿海信息',
        copyright: '粤ICP备14050408号-3 Copyright © 2014-2017珠海沿海信息技术有限公司版权所有',
    },
    index:{
        carousel1: '沉浸无界、开启未来、超越现实',
        carousel2: '沿海智创、赋能未来',
        carousel3: '不断创新、砥砺前行',
        carousel4: 'XR科技、拓展无限可能',
        solution: {
            grid1: { 
                test: '技术创新',
                dose: '持续推动VR、AR、AI等前沿技术的发展',
            },
            grid2: { 
                test: '市场拓展',
                dose: '具备广泛的市场网络和开拓能力。',
            },
            grid3: { 
                test: '行业经验',
                dose: '在多个领域积累了丰富的应用经验。',
            },
            grid4: { 
                test: '客户服务',
                dose: '提供优质的定制化解决方案和售后支持。',
            },
        },
        solutionConetnt:{
            title: '专业技术能力的解决方案业务',
            titleEn: 'SOLUTION BUSINESS',
            desc: '沿海信息科技有限公司具备卓越的专业技术能力，能够开发前沿的VR、AR、AI解决方案，提供跨行业的定制化服务，并通过系统集成与优化提升客户业务效率，同时确保技术系统的长期稳定运行与升级。',
        },
        techInfo: {
            leftTitle: '新一代沉浸式技术',
            leftTitleEn: 'NEW GENERATION',
            leftDesc: 'XR技术以其独特的沉浸式体验、广泛的应用场景和增强的交互性，彻底改变了用户与数字内容的互动方式。它能够灵活定制，适应不同需求，从娱乐到工业应用，推动各行业的创新与效率提升。作为前沿科技的代表，XR技术不仅打破了虚拟与现实的界限，还为未来的数字化转型创造了无限可能。',
            rightTips1: '新一代',
            rightTips2: '沉浸式技术',
        },
        businessArea: {
            title: 'BUSINESS AREA',
            titleMore: '了解更多',
            subtitle: '业 / 务 / 领 / 域',
            areaTopText1: 'VR智能培训',
            areaRowContent1: '运用虚拟现实技术构建逼真模拟环境，提供交互式、沉浸式的学习体验，并结合数据分析和智能反馈，提升培训效果和技能掌握度。',
            areaTopText2: 'AR场景互动',
            areaRowContent2: '通过增强现实技术，将虚拟元素融入现实环境中，使用户能够身临其境地与虚拟内容进行互动，增强体验的沉浸感和参与感。',
            areaTopText3: 'AI智能应用',
            areaRowContent3: '利用信息化技术为护理机构赋能，通过AI智能交互提供在线护理咨询、信息分析、健康画像等服务，构建个性化、专业化、实时化的护理方案，支持长期康护服务。',
            business_title: 'SOLUTION',
            business_subtitle: '智 / 能 / 方 / 案', 
            business_areaTopText1: '自主研发深度学习框架', 
            business_content1: '公司自主开发先进的深度学习框架，增强了技术自主性，能够提供高效、定制化的XR解决方案，提升市场竞争力。', 
            business_areaTopText2: '专业研发团队', 
            business_content2: '拥有一支经验丰富的研发团队，专注于前沿技术的创新和产品开发，确保公司在技术领域始终处于领先地位。',
            business_areaTopText3: '数据驱动的精准决策', 
            business_content3: '利用大数据分析能力，沿海信息能够为客户提供精准的业务决策支持，优化运营效率，推动智能化转型。',
            business_areaTopText4: '互联网的创新敏捷能力', 
            business_content4: '公司在互联网领域具备卓越的创新和敏捷能力，能够快速响应市场变化，及时推出创新产品和服务，保持行业竞争优势。',
        },
    },

    productService: {
        vrArTab1: 'VR智能培训',
        vrArTab2: 'AR场景互动',
        vrArTab3: 'AI智能应用',
        vrarTitleBox: 'VR技术正在变革培训行业，通过高度沉浸式的模拟场景，提供更真实和互动的学习体验。在飞行员和护理培训中，VR分别模拟驾驶舱和医院环境，让学员在安全的虚拟空间中提升技能和应对能力，显著提高培训效果和效率。',
        vrarListTitle1: '沉浸式学习',
        vrarListDesc1: '通过VR技术，学员能够身临其境地进行护理操作练习，增强学习效果。',
        vrarListTitle2: '互动式学习',
        vrarListDesc2: '通过虚拟模拟增强实践操作体验。',
        vrarListTitle3: '安全模拟',
        vrarListDesc3: '通过虚拟现实技术提供逼真的环境，安全地训练学员应对危险情况的技能。',
        vrarListTitle4: '资源丰富',
        vrarListDesc4: '多样化学习资源，支持随时随地学习。',
        solutionTitle: 'VR沉浸式智能飞行培训解决方案',
        solutionDesc: '打造高度逼真的虚拟环境，再现真实飞行的物理环境和天气条件，提供反复练习的机会，使飞行员在安全的虚拟环境中熟悉复杂操作，减少实际训练的风险和成本。',
        solutionBtn1: '高效',
        solutionBtn2: '灵活',
        solutionBtn3: '无风险',
        solutionBtn4: '个性化定制',
        solutionBtn5: '低成本',
        solutionTitle2: 'VR沉浸式智能护理培训解决方案',
        solutionDesc2: '利用沉浸式虚拟现实技术，模拟真实护理场景，精准提升护理人员的技能和应急能力，为医疗行业提供更高效、安全的培训解决方案。',
        // 
        ArSolutionTitle: 'AR沉浸式场景互动游戏引擎',
        ArSolutionDesc: '通过增强现实技术，将虚拟元素与现实世界无缝融合，为玩家创造身临其境的互动体验。游戏突破了传统的屏幕限制，让玩家在真实环境中参与互动，带来前所未有的沉浸感和乐趣。',
        ArSolutionBtn1: '身临其境的虚拟世界',
        ArSolutionBtn2: '个性化的动态反馈',
        ArSolutionBtn3: '高度互动的游戏体验',
        ArSolutionTitle2: '沉浸式VR冒险',
        ArSolutionSubtitle: '带给你不一样的游戏体验！',
        ArSolutionDesc2: 'VR沉浸式场景互动游戏通过高度逼真的虚拟环境和自由的互动体验，提供强烈的沉浸感，带来传统游戏无法比拟的参与度和现实感。VR与游戏相结合的形式不仅增强了娱乐性，还能提升玩家的专注力和学习效果，使虚拟体验更加生动、个性化和富有挑战性。',
        // 
        AiVrarTitle: 'AI技术正在赋能护理行业，通过智能平台实现精准数据处理和辅助决策，优化护理服务质量、提升资源利用效率，并推动护理服务的数字化升级。',
        AiSolutionTitle: 'AI驱动的智能护理解决方案',
        AiSolutionDesc: 'AI+护理平台通过构建护理行业知识库和语言模型，利用信息化技术为护理机构赋能，提供个性化、专业化、实时化的康护服务。同时通过AI智能交互（Nurse-Chat）和多维度数据分析，生成健康信息画像，支持长期专业护理方案的制定和实施。',
        aiInfoTitle: '智能护理优势与提升',
        aiGridTitle1: '提升服务满意度',
        aiGridContent1: '通过个性化护理和高效服务，提高患者和服务对象的满意度。',
        aiGridTitle2: '快速落地应用迭代',
        aiGridContent2: '神经节点知识库的护理评估和方案独立运行，支持快速推出特定护理服务并持续优化。',
        aiGridTitle3: '护理专业能力提升',
        aiGridContent3: '提供精准的辅助决策和培训，显著提升护理人员的专业技能。',
        aiGridTitle4: '人力需求优化',
        aiGridContent4: '通过智能调度和自动化流程，减少对人力的依赖，提高资源配置效率。',
        aiGridTitle5: '业务拓展能力提升',
        aiGridContent5: '通过智能化和数据驱动的分析，帮助护理机构更快地拓展业务范围。',
    },

    aboutUs: {
        aboutUsTitle: '沿海智创 · 赋能未来',
        aboutUsSubtitle: 'COMPANY PROFILE',
        aboutUsParagraph1: '珠海市沿海信息技术有限公司成立于2014年，2017年通过国家高新技术企业认证并拥有超过40项软件著作权，是美森博多利（硅谷）的珠海软件合作研发中心，擅长AI、XR、WEB3.0(区块链) 等技术领域的应用软件设计与研发。',
        aboutUsParagraph2: '公司核心成员来自国内知名软件研发企业的软件研发团队，拥有近二十年的软件合作研发经验以及成熟的研发架构，已经成功的完成了多个大型互联网、移动互联网、区块链和企业资源信息管理（ERP）软件项目。 熟悉的领域包括民航飞行员培训、民航飞机检测与航材管理、护理及养老专业评估与信息化管理，沉浸式AR剧本游戏等领域。曾服务的客户包括沃尔顿链，中国移动、中国电信、中外运、南方航空、全国各大报业集团、港澳大型零售连锁超市，大型眼镜连锁企业、大型医药连锁企业和苹果公司等国内外大型企业。',
        aboutUsSubtitle2: '融合科技前沿，推动智慧转型。',
        aboutUsParagraph3: '我们正竭尽全力，致力于成为AI及XR技术应用领域的专业引领者，通过整合AI与虚拟现实技术，为用户创造全新的业务价值与体验。',
        ideaList1: '我们不断创造新的经验和价值，通过对客户需求和技术能力的深刻理解，开辟无限可能。',
        ideaList2: '我们致力于创新服务和业务，始终将客户和合作伙伴放在首位，以优质的产品赢得信任。',
        ideaList3: '我们通过创新和合作，共同创造未来的价值。',
        ideaList4: '我们希望支持那些拥有梦想和激情的人才，并与商业伙伴共同成长。',
        ideaList5: '作为新技术时代的一员，沿海信息将通过满足客户需求的前沿技术、创新服务和解决方案，持续推动变革。',
        ideaList6: '我们期待您继续关注和支持我们在互联网领域的新挑战与机遇，共同迎接未来的可能性。',
        aboutUsTitle1: '欢迎合作',
        aboutUsSubtitle1: 'WELECOME TO COOPERATE',
        welecomeContentList1: '与我们携手，共同开创智能化和数字化时代的新篇章。',
        welecomeContentList2: '沿海，您的可靠合作伙伴，助力实现更高目标。',
        contactTitle1: '企业电话',
        contactSubtitle1: '86-756-8818398',
        contactTitle2: '邮箱E-MAIL',
        contactSubtitle2: 'business@coastalinfo.cn',
        contactTitle3: '电话联系',
        contactSubtitle3: '86-138-7664-4296',
        contactTitle4: '公司地址',
        contactSubtitle4: '珠海市香洲区奥园广场',
        contactSubtitle4_1: 'SY6-1501',
    }

    
  }